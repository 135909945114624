import React, {useEffect, useState} from "react";
import {Box, Dialog} from "@mui/material";
import {styled} from "@mui/material/styles";
import sizeConfigs from "/@/configs/sizeConfigs";
import Sidebar from "./Sidebar";
import appRoutes from "/@/routes/appRoutes";
import AppBottomNavigation from "/@/components/layout/AppBottomNavigation";
import AirAwareChatModal from "/@/components/chat/AirAwareChatModal";
import {useSupabase} from "/@/context/supabaseContext";
import {hotjar} from "react-hotjar";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

interface MainLayoutProps {
    children: React.ReactNode;
    isSidebarCollapsed?: boolean;
}

const PageContent = styled(Box)(({theme}) => ({
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
}));

const NavContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isCollapsed'
})<{ isCollapsed?: boolean }>(({theme, isCollapsed}) => ({
    width: isCollapsed ? sizeConfigs.sidebar.collapsedWidth : sizeConfigs.sidebar.width,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    flexShrink: 0,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
        display: 'none'
    },
}));

const MainContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isCollapsed'
})<{ isCollapsed?: boolean }>(({theme, isCollapsed}) => ({
    flexGrow: 1,
    padding: 0,
    width: `calc(100% - ${isCollapsed ? sizeConfigs.sidebar.collapsedWidth : sizeConfigs.sidebar.width})`,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    minHeight: '100vh',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));

const ContentContainer = styled(Box)(({theme}) => ({
    margin: 0,
    borderRadius: '24px 0 0 24px',
    backgroundColor: theme.palette.secondary.main,
    height: '100vh',
    overflow: 'auto',
    transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
        height: `calc(100vh - ${sizeConfigs.bottomNav.height})`,
        borderRadius: 0,
        padding: theme.spacing(2),
    },
    '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
    },
    '&::-webkit-scrollbar-track': {
        background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.2)'
            : 'rgba(0, 0, 0, 0.2)',
        borderRadius: '3px',
    },
}));

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function MainLayout({children}: { children: React.ReactNode }) {
    const {user} = useSupabase();
    const [value, setValue] = useState(0);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    useEffect(() => {
        const currentIndex = appRoutes.findIndex((route) => route.path === window.location.pathname);
        setValue(currentIndex);
    }, []);

    useEffect(() => {
        if (user && typeof window !== 'undefined') {
            hotjar.identify(user.id, {email: user.email});
        }
    }, [user]);

    const handleSidebarCollapse = (collapsed: boolean) => {
        setIsSidebarCollapsed(collapsed);
    };

    return (
        <PageContent id="page-content">
            <NavContainer component="nav" isCollapsed={isSidebarCollapsed}>
                <Sidebar onCollapse={handleSidebarCollapse}/>
            </NavContainer>
            <MainContainer component="main" isCollapsed={isSidebarCollapsed}>
                <AirAwareChatModal/>
                <ContentContainer>
                    {children}
                </ContentContainer>
                <AppBottomNavigation setValue={setValue} value={value}/>
            </MainContainer>
        </PageContent>
    );
}