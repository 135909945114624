import React from 'react';
import {RouteType} from "./config";
import {PeopleOutline, Info, Map, Settings, QuestionAnswer, Add} from "@mui/icons-material";

export const boroughRoutes: RouteType[] = [
    {
        path: "/boroughs/hackney",
        state: "hackney",
        sidebarProps: {
            displayText: "Hackney",
        },
    },
    {
        path: "/boroughs/newham",
        state: "newham",
        sidebarProps: {
            displayText: "Newham",
        },
    },
    {
        path: "/boroughs/city-of-london",
        state: "city-of-london",
        sidebarProps: {
            displayText: "City of London",
        },
    },
    {
        path: "/boroughs/tower-hamlets",
        state: "tower-hamlets",
        sidebarProps: {
            displayText: "Tower Hamlets",
        },
    },
];

const appRoutes: RouteType[] = [
    // {
    //     path: "/",
    //     state: "map",
    //     sidebarProps: {
    //         displayText: "map",
    //         icon: <Map/>,
    //     },
    // },
    {
        path: "/",
        state: "map",
        sidebarProps: {
            displayText: "map",
            icon: <Map/>,
            expandIcon: <Add/>, // Add expand icon for borough submenu
            boroughRoutes: boroughRoutes, // Add borough routes as child routes
        },
    },
    {
        state: "assistant",
        path: "/assistant",
        sidebarProps: {
            displayText: "assistant_button",
            icon: <QuestionAnswer/>,
        },
    },
    {
        path: "/infohub",
        state: "infohub",
        sidebarProps: {
            displayText: "infohub",
            icon: <Info/>,
        },
    },
    {
        path: "/community",
        state: "community",
        sidebarProps: {
            displayText: "community",
            icon: <PeopleOutline/>,
        },
    },
    {
        path: "/settings",
        state: "settings",
        sidebarProps: {
            displayText: "settings",
            icon: <Settings/>,
        },
    },
];

export default appRoutes;