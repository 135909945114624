import {
    IconButton,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, {useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {ShepherdTourContext} from "react-shepherd";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {deleteAccountBlacklist} from "/@/constants/accountList";
import {useSupabase} from "/@/context/supabaseContext";
import {AppDispatch, RootState} from "/@/store";
import {useDispatch, useSelector} from "react-redux";
import {setMapTourSeen} from "/@/store/slices/appStateSlice";

interface Props {
    handleDrawerOpen: (bool: boolean) => void;
    handleClose: () => void;
}

const MapTour: React.FC<Props> = ({handleDrawerOpen}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const portrait = useMediaQuery("(orientation: portrait)");
    const [isTourReady, setIsTourReady] = React.useState(false);
    const isMobile = mobile || portrait;
    const tour = useContext(ShepherdTourContext);
    const {user} = useSupabase(); // Consume the user from SupabaseProvider
    const dispatch: AppDispatch = useDispatch();
    const {mapTourSeenOnScreens} = useSelector((state: RootState) => state.appState);

    useEffect(() => {
        if (mapTourSeenOnScreens) return;
        else if (typeof localStorage !== 'undefined' && isTourReady) {
            const mapTour = JSON.parse(localStorage.getItem('mapTourCompleted') as string);
            if ((mapTour === null) || (!mapTour.tourSeen) || (user && deleteAccountBlacklist.includes(user.id))) {
                setTimeout(function () {
                    tour?.start();
                    if (user && deleteAccountBlacklist.includes(user.id)) {
                        dispatch(setMapTourSeen(true))
                    }
                }, 3000);
            }
        }
    }, [isTourReady, user]);


    const ensureDrawerOpen = () => {
        return new Promise<void>((resolve) => {
            if (isMobile) {
                // Open the drawer and resolve the promise after it's open
                handleDrawerOpen(true);
                // Use a setTimeout to allow for the drawer transition time
                setTimeout(resolve, 300); // Adjust the timeout to match the drawer's transition time
            } else {
                resolve(); // If not mobile, immediately resolve the promise
            }
        });
    };

    useEffect(() => {
        if (tour) {
            tour.addSteps([
                {
                    id: "index-tour-intro",
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("exit"),
                            action: tour.cancel,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("next"),
                            action: tour.next,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.welcome"),
                    text: [t("map_tour.welcome_text")],
                },
                {
                    id: "index-tour-overview",
                    attachTo: {element: ".tour-overview", on: "auto"},
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("back"),
                            action: tour.back,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("next"),
                            action: tour.next,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.overview"),
                    text: [t("map_tour.overview_text")],
                },
                {
                    id: "index-tour-map",
                    attachTo: {element: "#tour-map", on: "left"},
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("back"),
                            action: tour.back,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("next"),
                            action: tour.next,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.map"),
                    text: [t("map_tour.map_text")],
                },
                {
                    id: "index-tour-zoom-controls",
                    attachTo: {element: "#gmnoprint", on: "auto"},
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("back"),
                            action: tour.back,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("next"),
                            action: tour.next,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.zoom_controls"),
                    text: [t("map_tour.zoom_controls_text")],
                },
                {
                    id: "index-tour-address-search",
                    attachTo: {element: "#tour-address-search", on: "right"},
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("back"),
                            action: tour.back,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("next"),
                            action: tour.next,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.address_search"),
                    text: [t("map_tour.address_search_text")],
                },
                {
                    id: "index-tour-data-toggle",
                    attachTo: {element: "#tour-data-toggle", on: "left"},
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("back"),
                            action: tour.back,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("next"),
                            action: tour.next,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.data_toggle"),
                    text: [t("map_tour.data_toggle_text")],
                },
                {
                    id: "index-tour-key-legend",
                    attachTo: {element: "#tour-key-legend", on: "right"},
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("back"),
                            action: tour.back,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("next"),
                            action: tour.next,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.key_legend"),
                    text: [t("map_tour.key_legend_text")],
                },
                {
                    id: "index-tour-reset-view",
                    attachTo: {element: "#tour-reset-view", on: isMobile ? "auto" : "left"},
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("back"),
                            action: tour.back,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("next"),
                            action: tour.next,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.reset_view"),
                    text: [t("map_tour.reset_view_text")],
                },
                {
                    id: "index-tour-interactive-map-section",
                    attachTo: {element: "#tour-interactive-map-section", on: isMobile ? "bottom" : "left"},
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("back"),
                            action: tour.back,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("next"),
                            action: tour.next,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.interactive_map_section"),
                    text: [t("map_tour.interactive_map_section_text")],
                    beforeShowPromise: ensureDrawerOpen
                    // when: {
                    //   show() {
                    //     // Function to open the drawer for this step
                    //     if (isMobile) {
                    //       handleDrawerOpen(true); // Only open the drawer on mobile
                    //     }
                    //   },
                    // }
                },
                {
                    id: "index-tour-location-picker",
                    attachTo: {element: "#tour-location-picker", on: isMobile ? "bottom" : "left"},
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("back"),
                            action: tour.back,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("next"),
                            action: tour.next,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.location_picker"),
                    text: [t("map_tour.location_picker_text")],
                },
                {
                    id: "index-tour-guidelines-switch",
                    attachTo: {element: "#tour-guidelines-switch", on: isMobile ? "bottom" : "left"},
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("back"),
                            action: tour.back,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("next"),
                            action: tour.next,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.guidelines_switch"),
                    text: [t("map_tour.guidelines_switch_text")],
                },
                {
                    id: "index-tour-guidelines-switch-defra",
                    attachTo: {element: "#tour-guidelines-switch-defra", on: isMobile ? "bottom" : "left"},
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("back"),
                            action: tour.back,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("next"),
                            action: tour.next,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.defra_guidelines_heading"),
                    text: [t("map_tour.defra_guidelines_text")],
                },
                {
                    id: "index-tour-guidelines-switch-who",
                    attachTo: {element: "#tour-guidelines-switch-who", on: isMobile ? "bottom" : "left"},
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("back"),
                            action: tour.back,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("next"),
                            action: tour.next,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.who_guidelines_heading"),
                    text: [t("map_tour.who_guidelines_text")],
                },
                {
                    id: "index-tour-date-range-picker",
                    attachTo: {element: "#tour-date-range-picker", on: isMobile ? "bottom" : "left"},
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("back"),
                            action: tour.back,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("next"),
                            action: tour.next,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.date_range_picker"),
                    text: [t("map_tour.date_range_picker_text")],
                },
                {
                    id: "index-tour-pollution-graphs",
                    attachTo: {element: "#tour-pollution-graphs", on: isMobile ? "top" : "auto"},
                    buttons: [
                        {
                            classes: "shepherd-button-secondary",
                            text: t("back"),
                            action: tour.back,
                        },
                        {
                            classes: "shepherd-button-primary",
                            text: t("finish"),
                            action: tour.complete,
                        },
                    ],
                    scrollTo: {behavior: 'smooth', block: 'center'},
                    title: t("map_tour.pollution_graphs"),
                    text: [t("map_tour.pollution_graphs_text")],
                },
            ]);
            setIsTourReady(true);
            tour.on('start', () => {
                if (typeof localStorage !== 'undefined') {
                    localStorage.setItem('mapTourCompleted', JSON.stringify({tourSeen: true}));
                }
            });
        }
    }, [tour]);


    return (
        <Tooltip title={t("map_tour.tooltip")} placement="left">
            {isMobile ?
                <IconButton
                    sx={{
                        color: 'black', // Adjust icon color as needed
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    }}
                    aria-label={'Help Button'}
                    onClick={tour?.start}
                >
                    <HelpOutlineIcon/>
                </IconButton> :
                <IconButton
                    sx={{
                        color: 'black', // Adjust icon color as needed
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    }}
                    aria-label={'Help Button'} id="tour-start" onClick={tour?.start} color="primary">
                    <HelpOutlineIcon/>
                </IconButton>
            }
        </Tooltip>
    );
};

export default MapTour;
