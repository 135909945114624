export const sizeConfigs = {
  sidebar: {
    width: "260px", // Increased from 150px for better content display
    collapsedWidth: "64px",
  },
  bottomNav: {
    height: "90px",
  },
} as const;

export default sizeConfigs;