import React, {useState} from "react";
import {
    Box,
    Collapse,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Typography,
    useTheme,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "/@/store";
import {useTranslation} from "react-i18next";
import {motion} from "framer-motion";
import {navigate} from "vike/client/router";
import {resetChat} from "/@/store/slices/airAwareAssistantSlice";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {RouteType} from "/@/routes/config";

interface SidebarItemProps {
    index: number;
    item: RouteType;
    isCollapsed: boolean;
}

// Update the SidebarItem component to handle the updated RouteType
const SidebarItem = React.memo(({index, item, isCollapsed}: {
    index: number;
    item: RouteType;
    isCollapsed: boolean;
}) => {
    const theme = useTheme();
    const {appState} = useSelector((state: RootState) => state.appState);
    const {t} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);

    const handleMainClick = () => {
        if (item.path) {
            // Handle redirect if specified
            if (item.redirectTo) {
                navigate(item.redirectTo);
                return;
            }

            if (item.path === "/assistant") {
                dispatch(resetChat());
            }
            navigate(item.path);
        }
    };

    const handleExpandClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    // Custom expand icon support from route config
    const ExpandIcon = expanded ? (
        <KeyboardArrowUpIcon fontSize="medium"/>
    ) : (
        <KeyboardArrowDownIcon fontSize="medium"/>
    );

    return (
        <ListItem
            disablePadding
            sx={{
                flexDirection: 'column',
                mb: expanded ? 0 : 1,
                width: '100%',
            }}
        >
            <motion.div
                initial={{x: -20, opacity: 0}}
                animate={{x: 0, opacity: 1}}
                transition={{delay: index * 0.1}}
                style={{width: '100%'}}
            >
                <ListItemButton
                    onClick={handleMainClick}
                    sx={{
                        borderRadius: '8px',
                        mb: 0.5,
                        padding: isCollapsed ? theme.spacing(1) : theme.spacing(1.5),
                        paddingRight: isCollapsed ? 10 : 0,
                        backgroundColor: appState === item.path
                            ? theme.palette.secondary.main  // Blue background for active state
                            : 'transparent',
                        '&:hover': {
                            backgroundColor: appState === item.path
                                ? theme.palette.secondary.main  // Keep blue on hover when active
                                : theme.palette.mode === 'dark'
                                    ? 'rgba(255, 255, 255, 0.08)'
                                    : 'rgba(0, 0, 0, 0.04)',
                        },
                        // Text color
                        color: appState === item.path ? '#fff' : 'inherit',
                        transition: theme.transitions.create(['background-color', 'color'], {
                            duration: theme.transitions.duration.shortest,
                        }),
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between', // This ensures proper spacing
                        width: '100%',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}>
                            {item.sidebarProps?.icon && (
                                <ListItemIcon sx={{
                                    color: 'inherit',
                                    minWidth: isCollapsed ? 'auto' : 40,
                                    justifyContent: 'center',
                                }}>
                                    {item.sidebarProps.icon}
                                </ListItemIcon>
                            )}
                            {!isCollapsed && (
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: appState === item.path ? 600 : 400,
                                        fontSize: theme.typography.body1.fontSize,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {t(item.sidebarProps?.displayText || '')}
                                </Typography>
                            )}
                        </Box>
                        {item.sidebarProps?.expandIcon && !isCollapsed && (
                            <IconButton
                                onClick={handleExpandClick}
                                size="small"
                                sx={{
                                    alignSelf: 'flex-end',
                                    mr: 1,
                                    mb: 0.5,
                                    color: theme.palette.mode === 'dark'
                                        ? theme.palette.grey[400]
                                        : theme.palette.secondary.light,
                                    '&:hover': {
                                        backgroundColor: theme.palette.mode === 'dark'
                                            ? 'rgba(255, 255, 255, 0.08)'
                                            : 'rgba(0, 0, 0, 0.04)',
                                    },
                                }}
                            >
                                {ExpandIcon}
                            </IconButton>
                        )}
                    </Box>
                </ListItemButton>
            </motion.div>

            {item.sidebarProps?.boroughRoutes && !isCollapsed && (
                <>


                    <Collapse in={expanded} timeout="auto" unmountOnExit sx={{width: '100%'}}>
                        <List component="div" disablePadding sx={{pl: 4}}>
                            {item.sidebarProps.boroughRoutes.map((borough, boroughIndex) => (
                                <motion.div
                                    key={borough.state}
                                    initial={{opacity: 0, y: -10}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{delay: boroughIndex * 0.05}}
                                >
                                    <ListItemButton
                                        onClick={() => {
                                            if (borough.redirectTo) {
                                                navigate(borough.redirectTo);
                                                return;
                                            }
                                            if (borough.path) {
                                                navigate(borough.path);
                                            }
                                        }}
                                        sx={{
                                            borderRadius: '6px',
                                            mb: 0.5,
                                            pl: 2,
                                            py: 1,
                                            backgroundColor: appState === borough.path
                                                ? theme.palette.secondary.main
                                                : 'transparent',
                                            color: appState === borough.path ? '#fff' : 'inherit',
                                            '&:hover': {
                                                backgroundColor: appState === borough.path
                                                    ? theme.palette.secondary.main
                                                    : theme.palette.mode === 'dark'
                                                        ? 'rgba(255, 255, 255, 0.08)'
                                                        : 'rgba(0, 0, 0, 0.04)',
                                            },
                                        }}
                                    >
                                        {borough.sidebarProps?.icon && (
                                            <Box component="span" sx={{mr: 1.5}}>
                                                {borough.sidebarProps.icon}
                                            </Box>
                                        )}
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: theme.typography.body2.fontSize,
                                                fontWeight: appState === borough.path ? 500 : 400,
                                                color: appState === borough.path
                                                    ? theme.palette.mode === 'dark'
                                                        ? theme.palette.common.white
                                                        : theme.palette.primary.main
                                                    : 'inherit',
                                            }}
                                        >
                                            {t(borough.sidebarProps?.displayText ?? '')}
                                        </Typography>
                                    </ListItemButton>
                                </motion.div>
                            ))}
                        </List>
                    </Collapse>
                </>
            )}
        </ListItem>
    );
});

SidebarItem.displayName = "SidebarItem";
export default SidebarItem;


// types/route.ts
export interface BoroughRoute {
    path?: string;
    sidebarProps?: {
        displayText: string;
        icon?: React.ReactElement;
    };
}
