import dayjs from "dayjs";
import {ChosenPeriod, MapCenterCoords, TimePeriod} from "/@/types/mapTypes";
import {PollutantType} from "/@/constants/chartContants";

export const POLLUTANT_OPTIONS = ["pm10", "pm25", "no2"] as const;

export const METRIC_OPTIONS = ["avg", "max", "min"] as const;

export const METRIC_OPTION_TO_LABEL: Record<string, string> = {
    avg: "Average",
    max: "Max",
    min: "Min",
}
export const INTERVAL_OPTIONS = ["hour", "day", "week", "month"] as const;

export const INTERVAL_OPTION_TO_LABEL: Record<string, string> = {
    hour: "Hourly",
    day: "Daily",
    week: "Weekly",
    month: "Monthly",
}
export const MAP_VIEW_OPTIONS = ["last-day", "last-week", "last-month", "last-year", "match-graphs"] as const;
export const POLLUTANT_LIMITS = {
    DEFRA: {
        PM10: { '24h': 50, annual: 40 },
        'PM2.5': { annual: 20 },
        NO2: { hourly: 200, annual: 40 },
    },
    WHO: {
        PM10: { '24h': 45, annual: 15 },
        'PM2.5': { '24h': 15, annual: 5 },
        NO2: { '24h': 25, annual: 10 },
    },
};

export const INITIAL_BOROUGH = "Hackney";

export const DEFAULT_MAP_CENTER: MapCenterCoords = {
    lat: 51.5072,
    lng: -0.1276,
};

export const BOROUGH_LOCATIONS = {
    "Hackney": {lat: 51.5436, lng: -0.0554},
    "Newham": {lat: 51.5255, lng: 0.0352},
    "City of London": {lat: 51.515556, lng: -0.093056},
    "Tower Hamlets": {lat: 51.5203, lng: -0.0293},
};

export enum BOROUGH {
    Hackney = "hackney",
    Newham = "newham",
    TowerHamlets = "tower-hamlets",
    CityOfLondon = "city-of-london",
}

type Coordinates = {
    center: { lat: number; lng: number };
    zoom: number;
    bounds: {
        north: number;
        south: number;
        east: number;
        west: number;
    };
};

// Use a mapped type to enforce the link between the enum and the object
export const BOROUGH_COORDINATES: Record<BOROUGH, Coordinates> = {
    [BOROUGH.Hackney]: {
        center: { lat: 51.5536, lng: -0.064 },
        zoom: 6,
        bounds: {
            north: 51.5734,
            south: 51.5198,
            east: -0.0242,
            west: -0.0898,
        },
    },
    [BOROUGH.Newham]: {
        center: { lat: 51.5255, lng: 0.0352 },
        zoom: 7,
        bounds: {
            north: 51.5572,
            south: 51.4997,
            east: 0.0831,
            west: -0.0023,
        },
    },
    [BOROUGH.TowerHamlets]: {
        center: { lat: 51.5203, lng: -0.0293 },
        zoom: 6,
        bounds: {
            north: 51.5432,
            south: 51.4871,
            east: 0.0074,
            west: -0.0658,
        },
    },
    [BOROUGH.CityOfLondon]: {
        center: { lat: 51.5155, lng: -0.0922 },
        zoom: 10,
        bounds: {
            north: 51.5223,
            south: 51.5108,
            east: -0.0764,
            west: -0.1095,
        },
    },
} as const;


export const whoPollutantRanges: Record<PollutantType, {
    low: number;
    medium: number;
    high: number
}> = {
    'PM2.5': {low: 15, medium: 22, high: 100}, // WHO 24-hour limit for PM2.5 is 15 µg/m³
    'PM10': {low: 45, medium: 67, high: 100}, // WHO 24-hour limit for PM10 is 45 µg/m³
    'NO2': {low: 25, medium: 37, high: 100}, // WHO 24-hour limit for NO2 is 25 µg/m³
};
export const defraPollutantRanges: Record<PollutantType, {
    low: number;
    medium: number;
    high: number
}> = {
    'PM2.5': {low: 35, medium: 53, high: 100},
    'PM10': {low: 50, medium: 75, high: 100},
    'NO2': {low: 50, medium: 75, high: 100},
};