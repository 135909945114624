import {
    Avatar,
    Box,
    Button,
    Drawer,
    List,
    ListItem,
    Stack,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import logo from "/@/assets/air-aware-logo.svg";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import LanguageSelector from "../accessibility/LanguageSelector";
import {useTranslation} from "react-i18next";
import {motion} from "framer-motion";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setAppState} from "/@/store/slices/appStateSlice";
import {usePageContext} from "/@/renderer/usePageContext";
import {navigate} from 'vike/client/router';
import InfoIcon from "@mui/icons-material/Info";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";

interface SidebarConfig {
    width: string;
    collapsedWidth: string;
}

interface SidebarProps {
    onCollapse?: (collapsed: boolean) => void;
}

const Sidebar = ({onCollapse}: SidebarProps) => {
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const [offlineMode, setOfflineMode] = useState<boolean>(false);
    const [sidebarConfig, setSidebarConfig] = useState<SidebarConfig>({
        width: sizeConfigs.sidebar.width,
        collapsedWidth: "64px"
    });

    const [isCollapsed, setIsCollapsed] = useState(false);

    // Add this function
    const handleToggleCollapse = () => {
        const newCollapsed = !isCollapsed;
        setIsCollapsed(newCollapsed);
        onCollapse?.(newCollapsed);
    };


    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const portrait = useMediaQuery("(orientation: portrait)");
    const isMobile = mobile || portrait;
    const pageContext = usePageContext();
    const dispatch = useDispatch();

    // Track current route for active states
    useEffect(() => {
        if (pageContext.urlPathname) {
            dispatch(setAppState(pageContext.urlPathname));
        }
    }, [dispatch, pageContext.urlPathname]);

    // Handle offline mode
    useEffect(() => {
        if (typeof navigator !== 'undefined') {
            setOfflineMode(!navigator.onLine);
        }
    }, []);

    const changeLanguage = useCallback((language: string) => {
        i18n.changeLanguage(language);
    }, [i18n]);

    // Animation variants for interactive elements
    const buttonVariants = {
        hover: {scale: 1.1},
        tap: {scale: 0.95},
    };


    return (
        <Box component={motion.div}>
            <Drawer
                variant="permanent"
                sx={{
                    width: isCollapsed ? sizeConfigs.sidebar.collapsedWidth : sizeConfigs.sidebar.width,
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    flexShrink: 0,
                    display: {xs: "none", md: isMobile ? "none" : "block"},
                    "& .MuiDrawer-paper": {
                        width: isCollapsed ? sidebarConfig.collapsedWidth : sidebarConfig.width,
                        transition: theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        overflow: "hidden",
                        boxSizing: "border-box",
                        borderRight: "0px",
                        backgroundColor: theme.palette.mode === 'dark'
                            ? 'rgb(17, 24, 39)'
                            : theme.palette.primary.main,
                        color: theme.palette.mode === 'dark'
                            ? theme.palette.grey[100]
                            : theme.palette.secondary.light,
                        boxShadow: '0 4px 12px 0 rgba(0,0,0,0.05)',
                        '&:hover': {
                            boxShadow: '0 4px 12px 0 rgba(0,0,0,0.1)',
                        },
                    },
                }}
            >
                <Box sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    {/* Header Section */}
                    <Toolbar
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: theme.spacing(2),
                            minHeight: '64px',
                            borderBottom: `1px solid ${theme.palette.mode === 'dark'
                                ? 'rgba(255, 255, 255, 0.1)'
                                : 'rgba(0, 0, 0, 0.1)'}`
                        }}
                    >
                        <motion.div
                            initial={false}
                            animate={{
                                width: isCollapsed ? '40px' : '100%',
                                opacity: 1
                            }}
                            transition={{duration: 0.3}}
                        >
                            <Button
                                onClick={() => navigate('/about')}
                                sx={{
                                    padding: 1,
                                    backgroundColor: "transparent",
                                    color: 'inherit',
                                    textTransform: "none",
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    '&:hover': {
                                        backgroundColor: theme.palette.mode === 'dark'
                                            ? 'rgba(255, 255, 255, 0.08)'
                                            : 'rgba(0, 0, 0, 0.08)',
                                    },
                                    paddingLeft: isCollapsed ? 0 : 1,
                                }}
                                aria-label={t('about_air_aware')}
                            >
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems="center"
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Avatar
                                        src={logo}
                                        alt="Air Aware Logo"
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            backgroundColor: 'transparent',
                                        }}
                                    />
                                    {!isCollapsed && (
                                        <Box sx={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                                            <Typography
                                                variant="h6"
                                                component="span"
                                                sx={{
                                                    fontSize: '1.1rem',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Air Aware
                                            </Typography>
                                            <Tooltip title={t('learn_about_air_aware')} placement="top">
                                                <InfoIcon
                                                    sx={{
                                                        fontSize: '1rem',
                                                        ml: 1,
                                                        opacity: 0.7,
                                                        '&:hover': {
                                                            opacity: 1
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    )}
                                </Stack>
                            </Button>
                        </motion.div>
                    </Toolbar>

                    {/* Navigation Section */}
                    <List
                        sx={{
                            flex: 1,
                            padding: theme.spacing(2),
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            '&::-webkit-scrollbar': {
                                width: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                                background: 'transparent',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: theme.palette.mode === 'dark'
                                    ? 'rgba(255, 255, 255, 0.2)'
                                    : 'rgba(0, 0, 0, 0.2)',
                                borderRadius: '4px',
                            },
                        }}
                    >
                        {appRoutes.map((route, index) =>
                            route.sidebarProps ? (
                                <SidebarItem
                                    key={index}
                                    item={route}
                                    index={index}
                                    isCollapsed={isCollapsed}
                                />
                            ) : null
                        )}
                    </List>

                    {/* Footer Section */}
                    <Box sx={{
                        padding: theme.spacing(2),
                        borderTop: `1px solid ${theme.palette.mode === 'dark'
                            ? 'rgba(255, 255, 255, 0.1)'
                            : 'rgba(0, 0, 0, 0.1)'}`,
                    }}>
                        {!isCollapsed && (
                            <>
                                <ListItem>
                                    <LanguageSelector
                                        currentLanguage={currentLanguage}
                                        changeLanguage={changeLanguage}
                                        isSideBar={true}
                                    />
                                </ListItem>
                                {offlineMode && (
                                    <Typography
                                        color="error"
                                        textAlign="center"
                                        sx={{
                                            mt: 2,
                                            fontSize: '0.875rem',
                                            opacity: 0.9
                                        }}
                                    >
                                        {t("offline_mode")}
                                    </Typography>
                                )}
                            </>
                        )}
                        <Button
                            onClick={() => {
                                setIsCollapsed(!isCollapsed);
                                onCollapse?.(!isCollapsed);
                            }}
                            startIcon={isCollapsed ? <MenuIcon/> : <MenuOpenIcon/>}
                            sx={{
                                justifyContent: isCollapsed ? 'center' : 'flex-start',
                                color: theme.palette.mode === 'dark'
                                    ? theme.palette.grey[400]
                                    : theme.palette.secondary.light,
                                '&:hover': {
                                    backgroundColor: theme.palette.mode === 'dark'
                                        ? 'rgba(255, 255, 255, 0.08)'
                                        : 'rgba(0, 0, 0, 0.04)',
                                },
                                minWidth: 0,
                                px: isCollapsed ? 1 : 2,
                                py: 1,
                            }}
                        >
                            {!isCollapsed && (
                                <Typography variant="body2" sx={{ml: 1}}>
                                    {t('collapse_sidebar')}
                                </Typography>
                            )}
                        </Button>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
};

export default Sidebar;