import React, {useCallback} from 'react';
import {styled} from '@mui/material/styles';
import {Box, Chip, Stack, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "/@/store";
import {addNewMessage} from "/@/store/slices/airAwareAssistantSlice";
import {messageAssistant, formatMessage} from "/@/utils/assistantUtils";
import {useOnboardingContext} from "/@/context/onboardingContext";
import {useTranslation} from "react-i18next";
import {DotLottiePlayer} from "@dotlottie/react-player";
// @ts-ignore
import searchingAnimation from "/@/assets/animations/searching-animation.lottie";
// @ts-ignore
import QandAanimation from "/@/assets/animations/q-and-a.lottie";
// @ts-ignore
import visualLearningAnimation from "/@/assets/animations/visual-learning.lottie";

// Interfaces for the component props
interface SuggestionCardProps {
    title: string;
    suggestions: string[];
    gradientIndex: number;
}

interface GradientBackgroundProps {
    $gradientindex: number;
    $ismobile: boolean;
}

const GradientTypography = styled(Typography)(({theme}) => ({
    fontWeight: 'bold',
    backgroundClip: 'text',
    color: 'black',
    marginBottom: theme.spacing(2),
}));

const CustomChip = styled(Chip)(({theme}) => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    height: 'auto',
    '& .MuiChip-label': {
        display: 'block',
        whiteSpace: 'normal',
        padding: '10px'
    },
    p: 3
}));

// Define your gradients
const gradients = [
    'linear-gradient(135deg, #ffecd2 0%, #fcb69f 100%)', // Soft Pastel Gradient
    'linear-gradient(135deg, #A0FFDA 0%, #9BCDFF 100%)',
    'linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%)', // Greens and Blues Gradient
];


// Define a single set of keyframes for the animation
const gradientKeyframes = `
  @keyframes Gradient {
    0% { background-position: 0% 50% }
    15% { background-position: 25% 75% }
    30% { background-position: 50% 100% }
    45% { background-position: 75% 75% }
    60% { background-position: 100% 50% }
    75% { background-position: 75% 25% }
    90% { background-position: 50% 0% }
    100% { background-position: 25% 25% }
  }
`;

// Create a styled component for the GradientBackground
const GradientBackground = styled(Box, {
    shouldForwardProp: (prop) => prop !== '$ismobile' && prop !== '$gradientindex'
})<GradientBackgroundProps>(({theme, $gradientindex, $ismobile}) => ({
    background: gradients[$gradientindex % gradients.length], // Cycle through gradients
    backgroundSize: '400% 400%',
    animation: 'Gradient 8s ease infinite',
    borderRadius: '20px',
    border: '1px solid black',
    borderColor: 'black',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    minHeight: $ismobile ? '90%' : '420px',
    maxHeight: '90%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
}));

// SuggestionCard component
const SuggestionCard: React.FC<SuggestionCardProps & { gradientIndex: number }> = ({
                                                                                       title,
                                                                                       suggestions,
                                                                                       gradientIndex
                                                                                   }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const {t, i18n} = useTranslation();
    const dispatch: AppDispatch = useDispatch();

    const {
        chatHistory, sources, chatId
    } = useSelector((state: RootState) => state.airAwareAssistant);


    const memoizedFormatUserMessage = useCallback(
        (userMessage: string) => formatMessage(userMessage),
        [],
    );


    const handleReply = async (message: string) => {
        dispatch(addNewMessage(memoizedFormatUserMessage(message)));
        const currentLanguage = i18n.language;
        await messageAssistant(chatHistory, message, dispatch, chatId, currentLanguage, sources)
    }

    const animations = [searchingAnimation, QandAanimation, visualLearningAnimation]

    return (
        <>
            <style>{gradientKeyframes}</style>
            <GradientBackground
                $gradientindex={gradientIndex}
                $ismobile={isMobile}
                sx={{
                    animationPlayState: 'running',
                }}
            >
                <Box sx={{width: '100%', height: '100px', display: 'flex', justifyContent: 'center'}}>
                    <DotLottiePlayer
                        src={animations[gradientIndex]}
                        autoplay
                        loop
                    >
                    </DotLottiePlayer>
                </Box>
                <GradientTypography sx={{fontSize: undefined}} variant={'h5'}>{title}</GradientTypography>
                <Stack direction="column" spacing={1}>
                    {suggestions.map((suggestion, index) => (
                        <CustomChip color="primary" clickable key={index} label={suggestion} onClick={async () => {
                            await handleReply(suggestion);
                        }
                        }/>
                    ))}
                </Stack>
            </GradientBackground>
        </>
    );
};

export default SuggestionCard;
